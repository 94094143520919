@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Montserrat:wght@600&display=swap');
//colors
$orange: #BFA059;
$white: #fff;
$black: rgb(41,
41,
41);
// Base
html {
    padding: 0;
    margin: 0;
    height: 100%;
}

body {
    height: 100%;
    background-color: #001A57;
    overflow-x: hidden;
}

h1 {
    font-family: 'Baskervville', serif;
    color: $orange;
    font-size: 1.3em;
}

h2 {
    font-family: 'Baskervville', serif;
    color: #fff;
    font-size: 2.3em;
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: center;
    padding-bottom: 20px;
}

h3 {
    font-family: 'Baskervville', serif;
    color: #fff;
    font-size: 1em;
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 10px 0px 10px 0px;
}

#playervideo {
    position: relative;
    top: 0px;
    margin: 0 auto;
}

#banner {
    width: 100%;
}

:root {
    --main-white-color: #203986;
    --main-black-color: black;
    --main-purple-color: #9e89b8;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}


/* NEW
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.container {
    display: flex;
    flex-direction: column;
}

#logo {
    border-left: 1px solid #FFB711;
    border-right: 1px solid #FFB711;
    padding: 0px 40px;
    width: 160px;
    margin: auto;
    background-color: #001A57;
}

#zonehead {
    background-image: url('./../media/filet3.png');
    background-repeat: no-repeat;
    background-position: center center;
}

#page-intro {
    padding: 10px 0px 20px 0px;
}

#formulaires {
    text-align: center;
}

.white {
    color: #ffffff;
}


/* –––––––––––––––––––––––––––––––––––––––––––––––––– */


/* CONTAINER
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrapper {
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
    // background: rgb(255, 255, 255);
    // background: -moz-radial-gradient(circle, rgba(255, 255, 255, 1) 79%, #f7f3df 94%);
    // background: -webkit-radial-gradient(circle, rgba(255, 255, 255, 1) 79%, #f7f3df 94%);
    // background: radial-gradient(circle, rgba(255, 255, 255, 1) 79%, #f7f3df 94%);
}

.wrapper>* {
    padding: 0px;
}


/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.page-header {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-content: center;
    text-align: center;
    background-image: url('./../media/filet3.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #001A57;
    width: 100%;
    height: 100px;
    opacity: 1;
    margin-bottom: 20px;
}

// .page-header>div {
//     // background-color: rgba(0, 0, 0, .2);
//     display: flex;
//     width: 100%;
//     height: 100%;
// }

/* LOGO
–––––––––––––––––––––––––––––––––––––––––––––––––– */

#baseline {
    margin: 20px auto;
    text-align: center;
    justify-content: center;
    color: #FFF;
    font-family: 'Baskervville', serif;
    font-size: .9em;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 170px;
}

#languedoc {
    margin: 10px 0px 0px 0px;
    text-align: center;
    justify-content: center;
    color: #FFF;
}

#logoclos {
    margin: 30px 0px 0px 0px;
    color: #BFA059;
    text-align: center;
}

#logoclos>img {
    width: 130px;
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.page-footer {
    text-align: center;
    font-family: 'Baskervville', serif;
    font-size: 14px;
    // background-image: url('./../media/filet-or.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: contain;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #BFA059;
    padding-bottom: 10px;
}


/* FORM
–––––––––––––––––––––––––––––––––––––––––––––––––– */

#contactform {
    width: 60%;
    margin: auto;
}

.pass {
    border: 1px solid rgb(224, 224, 224);
    padding: 10px;
    margin: 20px;
}

.inpu {
    border: 1px solid rgb(224, 224, 224);
    padding: 5px;
    margin: 5px;
    width: 100%;
}

label {
    color: #fff;
    width: 140px;
    display: inline-block;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

.submit {
    border: 1px solid rgb(224, 224, 224);
    padding: 8px;
    margin: 5px;
    width: 100%;
    background-color: #001A57;
    color: #fff;
}


/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media (min-width: 1281px) {
    /* CSS */
}


/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
    /* CSS */
}


/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
    /* CSS */
    .page-footer {
        display: none;
    }
}


/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* CSS */
}


/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
    // /* CSS */
    // .page-header,
    // .page-footer {
    //     display: none;
    // }
    // .page-langue,
    // .page-secret {
    //     top: 40%;
    // }
    // .page-main {
    //     top: 17%;
    // }
    // h2 {
    //     font-size: 1.3em;
    // }
}


/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
    // /* CSS */
    // .page-langue,
    // .page-secret {
    //     top: 40%;
    // }
    // .page-main {
    //     top: 30%;
    // }
    // h2 {
    //     font-size: 1.3em;
    // }
    h3 {
        font-size: 0.8em;
        padding: 15px;
    }
    #logoclos>img {
        width: 90px;
    }
}


/* BTN
–––––––––––––––––––––––––––––––––––––––––––––––––– */

 :active,
 :hover,
 :focus {
    outline: 0!important;
    outline-offset: 0;
}

 ::before,
 ::after {
    position: absolute;
    content: "";
}

.btn-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
}

.btn {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0px 25px 15px;
    min-width: 150px;
}

.btn span {
    position: relative;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    transition: 0.3s;
}

.btn-4 span {
    // color: rgb(28, 31, 30);
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
}

.btn-4 span:hover {
    // color: rgb(54, 56, 55);
    background-color: rgba(0, 0, 0, 0.3);
    color: #FFF;
}

.btn-4::before,
.btn-4::after {
    width: 15%;
    height: 2px;
    background-color: #BFA059;
    z-index: 2;
}

.btn.hover-border-7::before,
.btn.hover-border-7::after {
    bottom: 0;
    transition: width 0.2s 0.35s ease-out;
}

.btn.hover-border-7::before {
    right: 50%;
}

.btn.hover-border-7::after {
    left: 50%;
}

.btn.hover-border-7:hover::before,
.btn.hover-border-7:hover::after {
    width: 50%;
    transition: width 0.2s ease-in;
}

.btn.hover-border-7 span::before,
.btn.hover-border-7 span::after {
    width: 0%;
    height: 0%;
    background: transparent;
    opacity: 0;
    z-index: 2;
    transition: width 0.2s ease-in, height 0.15s 0.2s linear, opacity 0s 0.35s;
}

.btn.hover-border-7 span::before {
    bottom: 0;
    left: 0;
    border-left: 2px solid #BFA059;
    border-top: 2px solid #BFA059;
}

.btn.hover-border-7 span::after {
    bottom: 0;
    right: 0;
    border-right: 2px solid #BFA059;
    border-top: 2px solid #BFA059;
}

.btn.hover-border-7 span:hover::before,
.btn.hover-border-7 span:hover::after {
    width: 50%;
    height: 96%;
    opacity: 1;
    transition: height 0.2s 0.2s ease-in, width 0.2s 0.4s linear, opacity 0s 0.2s;
}


/* LOADING
–––––––––––––––––––––––––––––––––––––––––––––––––– */

*.hidden {
    display: none !important;
}

div.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 10;
}

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.uil-ring-css {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 200px;
}

.uil-ring-css>div {
    position: absolute;
    display: block;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 80px;
    box-shadow: 0 6px 0 0 #ffffff;
    -ms-animation: uil-ring-anim 1s linear infinite;
    -moz-animation: uil-ring-anim 1s linear infinite;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    -o-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite;
}

.video-js .vjs-fullscreen-control {
    // display: none;
}

// .video-js {
//     position: relative !important;
//     width: 100% !important;
//     height: auto !important;
// }
// .video-js .vjs-tech {
//     position: fixed;
// }